import * as React from "react";
import { Fade } from 'react-reveal'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'

import Button from '../components/Button'
import Card from '../components/Card'
import CardExpertises from '../components/CardExpertises'
import Footer from '../components/Footer'
import ListItemVariant from '../components/ListItemVariant'
import Navbar from '../components/Navbar'
import figma from '../assets/figma.png'
import framer from '../assets/framer.png'
import nebuleuse from '../assets/images/projects/design-system__nebuleuse.webp'
import notion from '../assets/notion.png'
import walletx from '../assets/images/projects/design-concept__wallet-x.webp'

const DesignPage = () => {
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    setIsLoading(false)
  }, [])

  return (
    <main className="bg-[url('../assets/images/background-3.webp')] bg-cover bg-no-repeat text-white">
      <Helmet htmlAttributes={{ lang: 'fr-FR' }}>
        <title>
          Design | Ksatra SEM - Fullstack designer freelance à Toulouse
        </title>
        <meta
          name="description"
          content="Concevoir des interfaces marquantes, mémorables et des expériences utilisateurs significatives."
        />
      </Helmet>

      {!isLoading && <Navbar bgColor="bg-black" textColor="text-white" />}

      <div className="flex flex-col gap-24 pt-8 text-white lg:pt-24">
        {/* Jumbo */}
        <div className="px-6 lg:px-32">
          <Fade delay={800}>
            <h3 className="pb-6 text-lg">Design.</h3>
          </Fade>
          <Fade bottom>
            <h1 className="font-serif text-4xl leading-tight text-white lg:text-6xl">
              Concevoir des interfaces marquantes, mémorables et des expériences
              utilisateurs
              <span className="font-serif"> significatives </span>
            </h1>
          </Fade>
        </div>
        <Fade bottom>
          <div className="px-6 lg:px-32">
            <p className="mb-8 text-gray-200">Votre besoin ?</p>
            <ul className="flex flex-col gap-4 font-serif text-lg">
              <li>Refonte de site web</li>
              <li>Conception d'une application web / mobile</li>
              <li>POC / MVP</li>
            </ul>
          </div>
        </Fade>
        <Fade bottom>
          <div className="px-6 lg:px-32">
            <div className="mb-8 text-gray-200">Expertises</div>
            <div className="grid items-stretch gap-8 md:grid-cols-3">
              <CardExpertises
                className="row-span-1"
                title="UX/UI"
                description="La conception centrée utilisateur permet d'offrir une navigation intuitive et fluide. Une interface web capable de les séduire."
                textDescription="text-gray-200"
              />
              <CardExpertises
                className="row-span-1"
                title="Design system"
                description="Pour une durabilité de votre projet, le design system vous permet de centraliser toute la documentation et les composants développés."
                textDescription="text-gray-200"
              />
              <CardExpertises
                className="row-span-1"
                title="DesignOps"
                description="Le DesignOps vous permet de fluidifier le processus, de trouver les bons outils quelque soit la maturité de votre équipe dans une conduite saine du changement."
                textDescription="text-gray-200"
              />
            </div>
          </div>
        </Fade>

        <Fade bottom>
          <div className="mx-6 flex flex-col justify-center gap-8 rounded-lg bg-[url('../assets/images/card-cover.svg')] bg-cover bg-no-repeat py-8 lg:mx-32">
            <div>
              <p className="text-center font-serif text-4xl md:text-5xl">
                Mes outils
              </p>
            </div>
            <div className="flex flex-col justify-center gap-6 px-6 md:flex-row lg:gap-8">
              <ListItemVariant title="Figma" urlLogo={figma} />
              <ListItemVariant title="Framer" urlLogo={framer} />
              <ListItemVariant title="Notion" urlLogo={notion} />
            </div>
          </div>
        </Fade>

        <Fade bottom>
          <div className="mt-24 px-6 lg:px-32">
            <h2 className="mb-16 text-left font-serif text-4xl md:text-5xl">
              Projets associés
            </h2>
            <div>
              <div className="grid gap-8 md:grid-cols-2">
                <Card
                  description="Construction d'un Design System sous Figma"
                  expertises={['Design System', 'DesignOps']}
                  link="https://dribbble.com/shots/19417125-N-buleuse-Design-System"
                  media={nebuleuse}
                  mediaAlt="Aperçu Design System de Nébuleuse"
                  title="Nébuleuse Design System"
                />
                <Card
                  className="hidden md:block"
                  description="Concept design"
                  expertises={['UI Design']}
                  link="https://dribbble.com/shots/19417224-WalletX-Crypto-tracking"
                  media={walletx}
                  mediaAlt="Aperçu walletx portefeuille crypto"
                  title="WalletX"
                />
              </div>
              <div className="mt-16 text-center">
                <Link to="/play">
                  <Button title="Découvrir d'autres projets" />
                </Link>
              </div>
            </div>
          </div>
        </Fade>
      </div>

      <Fade bottom>
        <Footer />
      </Fade>
    </main>
  )
}

export default DesignPage;
